<template>
  <div class="submitModal">
    <div class="my-2 d-flex justify-content-between align-items-center">
      <div>
        <h4>تفاصيل المتقدم</h4>
      </div>
      <div>
        <b-avatar
          variant="info"
          size="4rem"
          src="https://placekitten.com/300/300"
        ></b-avatar>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <p>حالة المتقدم:</p>
        <p class="background rounded-pill">المشترك</p>
      </div>
    </div>

    <b-row>
      <b-col md="6">
        <div class="d-flex justify-content-start flex-column">
          <h4>معلومات الخبرة العمل</h4>
          <ek-input-text
            v-model="submitInfo.lastScientificDegree"
            label=" درجة العلمية  "
            name="  درجة العلمية  "
            readonly
          ></ek-input-text>
          <ek-input-text
            v-model="submitInfo.schoolName"
            label=" المدرسة الجامعة  "
            name="  المدرسة الجامعة  "
            readonly
          ></ek-input-text>
          <ek-input-text
            v-model="submitInfo.graduationDate"
            label=" تاريخ التخرج  "
            name="  تاريخ التخرج   "
            readonly
          ></ek-input-text>
          <ek-input-text
            v-model="submitInfo.languages"
            label=" اللغات    "
            name="  اللغات    "
            readonly
          ></ek-input-text>
          <ek-input-textarea
            v-model="submitInfo.skills"
            label=" المهارات    "
            name="  المهارات    "
            readonly
          >
          </ek-input-textarea>
          <div class="line-v"></div>
        </div>
      </b-col>
      <b-col md="6">
        <h4>المعلومات الشخصية</h4>
        <div class="icon text-right w-100 d-flex flex-column">
          <div class="d-flex justify-content-end align-items-center">
            <p class="mt-1 mr-1">{{ submitInfo.contactInfo.phoneNumber }}</p>
            <unicon name="mobile-android"></unicon>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <p class="mt-1 mr-1">{{ submitInfo.contactInfo.email }}</p>
            <unicon name="envelope"></unicon>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <p class="mt-1 mr-1">
              {{ new Date(submitInfo.contactInfo.birthDate).toLocaleDateString() }}
            </p>
            <unicon name="calender"></unicon>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <p class="mt-1 mr-1">
              {{ submitInfo.contactInfo.gender }}
            </p>
            <unicon name="calender"></unicon>
          </div>
        </div>
        <h4 class="mt-2">وسائل التواصل الاجتماعي</h4>
        <div class="icon text-right w-100 d-flex flex-column">
          <unicon name="facebook-f"> </unicon>
          <unicon name="linkedin-alt"></unicon>
          <unicon name="instagram"></unicon>
          <unicon name="twitter-alt"></unicon>
          <unicon name="youtube"></unicon>
        </div>
        <h4 class="mt-2">السيرة الذاتية</h4>
        <div
          class="icon w-100 d-flex justify-content-center align-items-center flex-column"
        >
          <unicon class="file" name="file-alt"></unicon>
          <p class="my-1">vey soon it's over</p>
          <b-button variant="primary">download</b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({ submitInfo: ({ jobs }) => jobs.submitDto }),
  },
};
</script>
<style lang="scss">
.submitModal {
  .unicon {
    &.file {
      svg {
        height: 40px;
        width: 40px;
      }
    }
    margin-top: 10px;
    svg {
      fill: #5e5873;
    }
  }
  .line-v {
    border-left: 2px solid #d8d6de;
    height: 500px;
    position: absolute;
    left: 100%;
  }
}
</style>
