<template>
  <div class="details">
    <b-row>
      <b-col md="8">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
              <unicon class="m-0" name="bag"></unicon>
              <h4 class="mx-2 mb-0 title">تفاصيل الوظيفة</h4>
            </div>
            <div class="d-flex justify-content-start align-items-center">
              <h4 class="mx-1 mb-0 title">حالة الوظيفية:</h4>
              <StatusBadge
                class="orders-table__status-badge"
                :statusList="jobStatuses"
                :selectedStatusNumber="jobDto.subStatuses"
              />
            </div>
          </div>
          <div class="mt-3">
            <ValidationObserver ref="activityForm">
              <b-row>
                <b-col md="6">
                  <ek-input-select
                    :options="activities"
                    :rules="[
                      {
                        type: 'required',
                        message: 'اسم الفعالبة مطلوب',
                      },
                    ]"
                    :disabled="true"
                    v-model="jobDto.senderId"
                    label="اسم الفعالية"
                    name="اسم الفعالية"
                  ></ek-input-select>
                </b-col>
                <b-col md="6">
                  <ek-input-text
                    readonly
                    :rules="[
                      {
                        type: 'required',
                        message: 'اسم النشاط مطلوب',
                      },
                    ]"
                    v-model="jobDto.comertialAcitvityName"
                    label=" النشاط التجاري"
                    name="النشاط التجاري "
                  ></ek-input-text>
                </b-col>
                <b-col md="6">
                  <ek-input-select
                    :options="jobTypes"
                    :rules="[
                      {
                        type: 'required',
                        message: ' نوع العمل مطلوب',
                      },
                    ]"
                    v-model="jobDto.jobType"
                    label="نوع العمل "
                    name=" فرع العمل "
                  ></ek-input-select>
                </b-col>
                <b-col md="6">
                  <ek-date-picker
                    :rules="[
                      {
                        type: 'required',
                        message: '  موعد مطلوب',
                      },
                    ]"
                    v-model="jobDto.deadLineDate"
                    label=" اخر موعد للعمل"
                    name=" اخر موعد للعمل"
                  ></ek-date-picker>
                </b-col>
                <b-col md="6">
                  <ek-input-text
                    readonly
                    v-model="jobDto.cityName"
                    name="المدينة"
                    label="المدينة"
                  ></ek-input-text>
                </b-col>
                <b-col md="6">
                  <ek-input-text
                    readonly
                    v-model="jobDto.submitsCount"
                    name="عدد المتقدمين "
                    label="عدد المتقدمين"
                  ></ek-input-text>
                </b-col>
                <div
                  class="mx-1 w-100 d-flex justify-content-md-between align-items-center flex-wrap justify-content-center"
                >
                  <b-button
                    class="mt-1 mt-md-0"
                    variant="primary"
                    @click="navigateToActivityDetails(jobDto.senderId)"
                    >تفاصيل الفعالية</b-button
                  >
                  <div class="mt-1 mt-md-0">
                    <b-button class="mx-1" @click="editJob(jobDto)" variant="primary">
                      التعديل</b-button
                    >
                    <!--X class="border" -->
                    <b-button variant="outline-primary" to="/admin/jobs"
                      >التراجع
                    </b-button>
                  </div>
                </div>
              </b-row>
            </ValidationObserver>
          </div>
        </b-card>
        <b-row>
          <b-col md="12">
            <b-card>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="title">{{ jobDto.jobTitle }}:</h4>
                <p>
                  تاريخ الاعلان الوظيفة
                  {{ new Date(jobDto.responseDate).toLocaleDateString() }}
                </p>
              </div>
              <div class="line"></div>
              <p class="my-1">
                {{ jobDto.description }}
              </p>
              <div class="line"></div>
              <b-row>
                <div
                  class="w-100 d-flex justify-content-center justify-content-md-end align-items-center mx-1"
                >
                  <span class="mx-1">رابط التقديم الوظيفة:</span>
                  <span>{{ jobDto.extraUrl }}</span>
                </div>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <b-card body-class="p-0">
          <div class="p-1 d-flex justify-content-between align-items-center">
            <h4>المتقدمين</h4>
            <p class="mx-1 background-perple rounded-pill">
              {{ jobDto.submites.length }}متقدم
            </p>
          </div>
          <div class="line-bottom"></div>
          <div
            class="presents d-flex justify-content-between align-items-center"
            v-b-modal="'ek-modal'"
            v-for="submit in jobDto.submites"
            :key="submit.id"
            @click="getIdSubmit(submit.jobInfoId)"
          >
            <div class="d-flex justify-content-start align-items-center">
              <b-avatar
                variant="info"
                size="4rem"
                src="https://placekitten.com/300/300"
              ></b-avatar>
              <div class="mx-1">
                <strong> {{ submit.name }}</strong>
                <p>{{ submit.location }}</p>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-column">
              <p>{{ new Date(submit.dateTime).toLocaleDateString() }}</p>
              <unicon class="mt-0" name="angle-left"></unicon>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <ek-modal title="img" noHeader noFooter>
      <JobModalInfo></JobModalInfo>
    </ek-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import JobModalInfo from "../components/JobModalInfo.vue";
import StatusBadge from "@global/components/StatusBadge.vue";

export default {
  components: {
    JobModalInfo,
    StatusBadge,
  },
  props: ["id"],
  methods: {
    editJob(newDto) {
      this.$refs.activityForm.validate().then((suc) => {
        if (suc) {
          this.editJobDto(newDto);
          this.$refs["activityForm"].reset();
        }
      });
    },
    getIdSubmit(id) {
      this.getJobsSubmit(id);
    },
    navigateToActivityDetails(id) {
      let activity = this.activities.find((el) => el.id == id);
      console.log(activity);
      this.$router.push({
        path: `/admin/activities/${id}`,
        query: { username: activity.name },
      });
    },
    ...mapActions(["editJobDto", "getJobsSubmit"]),
  },
  computed: {
    ...mapState({
      jobDto: (state) => state.jobs.jobDto,
      activities: (state) => state.activities.activities,
      jobTypes: (state) => state.jobs.jobTypes,
      jobStatuses: (state) => state.users.userJobStatus,
    }),
  },
  created() {
    this.$store.dispatch("getByIdDash", this.id);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/variables";
p {
  margin: 0 !important;
  &.background {
    padding: 2px;
    color: #57d18c;
    background-color: #efefef;
  }
  &.background-perple {
    padding: 2px;
    color: #db7adb;
    background-color: #efefef;
  }
}
.details {
  .unicon {
    svg {
      fill: #5e5873;
      cursor: pointer;
    }
  }
  h4 {
    &.title {
      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
  }
  button {
    &.border {
      background-color: #fff !important;
      border: 1px solid $primary !important;
      color: $primary !important;
    }
  }

  .line {
    margin: 10px 0 21px 0;
    width: 100%;
    border-bottom: 2px solid #d8d6de;
  }
  .line-bottom {
    width: 100%;
    border-bottom: 2px solid #d8d6de;
  }
  .presents {
    padding: 20px;
    cursor: pointer;
    .b-avatar-img {
      strong {
        color: #5e5873;
      }
    }
  }
}
</style>
